<template>
  <div class="indexbox">
    <nav-bar title="交易大厅" left-arrow />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="bodybox">
        <div class="num">
          <div>
            <p>LONGKA 价格</p>
            <p>{{ info.cea_price || 0 }} USDT</p>
          </div>
          <img src="@/assets/img/ys@2x.png" alt="" />
        </div>
        <div class="buy">
          <!-- <div>
            <p>{{ info.allow_today || 0 }}LONGKA</p>
            <p>今日可购买LONGKA额度</p>
          </div> -->
          <div @click="sendSell">
            <p>发布卖单</p>
            <!-- <p>数量为{{ info.allow_sell }}</p> -->
          </div>
<!--          <div v-else>-->
<!--            <p>不能发布卖单</p>-->
<!--            <p>需先匹配买一单</p>-->
<!--          </div>-->
        </div>

        <!-- 敬请期待～ -->
        <!-- <p class="tit">交易大厅</p>
        <div class="icce">
          <div class="ice">
          </div>
          <div class="wkf"><img src="~@/assets/img/wkf.png" alt="" /></div>
          <div class="wk">敬请期待～</div>
        </div> -->
        <div style="height: 5vw"></div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="0"
        >
          <div class="item" v-for="val in list" :key="val.id">
            <div class="info">
              <img v-if="val.avatar" :src="val.avatar" alt="" />
              <img v-else src="@/assets/img/toux@2x.png" alt="" />
              <p>{{ val.address }}</p>
              <van-button size="small" @click="changeItem(val)"
                >匹配购买</van-button
              >
            </div>
            <div class="nums">
              <div>
                <p>{{ val.surplus_num }}底池币</p>
                <p>发布数量</p>
              </div>
              <div>
                <p>{{ val.total_usdt }}USDT</p>
                <p>大约价值</p>
              </div>
            </div>
            <div class="time">
              <span>发布时间</span>
              <span>{{ val.created_at }}</span>
            </div>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
    <van-popup
      class="popupbox"
      v-model="show"
      :close-on-click-overlay="false"
      closeable
    >
      <div class="content">
        <p class="tit">立即购买</p>
        <van-field
          v-model="num"
          placeholder="输入购买数量"
          clearable
          :border="false"
        />
        <div class="e">
          <p>
            剩余数量：<span>{{ surplus_num }}</span>
          </p>
        </div>
        <p class="n"></p>
        <div class="btns">
          <van-button color="#D1D1D1" @click="show = false">取消</van-button>
          <van-button :loading="aloading" @click="submit">提交</van-button>
        </div>
      </div>
    </van-popup>
    <van-popup
      class="popupbox"
      v-model="send_show"
      :close-on-click-overlay="false"
      closeable
    >
      <div class="content">
        <p class="tit">发布卖单</p>
        <van-field
          v-model="send_num"
          placeholder="输入售卖底池币数量..."
          clearable
          :border="false"
        />
        <p class="n" style="margin-bottom: 25px">
          当前拥有：<span>{{ user.dichi }}底池币</span>
        </p>
        <div class="btns">
          <van-button color="#D1D1D1" @click="send_show = false"
            >取消</van-button
          >
          <van-button :loading="bloading" @click="publish">立即发布</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      user: {},
      info: {},
      list: [],
      page: 1,
      loading: false,
      finished: false,
      id: "",
      surplus_num: "",
      num: "",
      show: false,
      aloading: false,
      send_num: "",
      send_show: false,
      bloading: false,
      refreshing: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getdata();
  },
  mounted() {},
  methods: {
    getdata() {
      this.$get({
        loading: true,
        url: "/trade_order/get_num",
        success: (res) => {
          this.info = res.data;
        },
        tip: () => {},
      });
      this.$get({
        url: "/user/info",
        success: (res) => {
          this.user = res.data;
        },
        tip: () => {},
      });
    },
    onLoad() {
      let page = this.page++;
      this.$get({
        url: "/trade_order/lists",
        data: {
          page,
        },
        success: (res) => {
          if (!res.data || res.data.data.length == 0) {
            this.finished = true;
          } else {
            var list = res.data.data;
            if (page == 1) {
              this.list = list;
            } else {
              for (var i in list) {
                this.list.push(list[i]);
              }
            }
          }
          this.loading = false;
        },
        tip: () => {
          this.finished = true;
        },
      });
    },
    changeItem(data) {
      this.id = data.id;
      this.surplus_num = data.surplus_num;
      this.num = "";
      this.show = true;
    },
    // 匹配卖单
    submit() {
      if (!this.num) return this.$toast("输入匹配购买数量");
      this.aloading = true;
      this.$post({
        loading: true,
        url: "/trade_order/match_sell",
        data: { num: this.num, order_id: this.id },
        success: (res) => {
          this.getdata();
          this.num = "";
          this.id = "";
          this.$toast({
            message: "匹配成功",
            duration: 2500,
            onClose: () => {
              this.show = false;
              this.aloading = false;
              this.page = 1;
              this.list = [];
              this.finished = false;
              this.onLoad();
            },
          });
        },
        tip: () => {
          this.aloading = false;
        },
      });
    },
    sendSell() {
      // this.send_num = this.info.allow_sell;
      this.send_show = true;
    },
    // 发布卖单
    publish() {
      if (!this.send_num) return this.$toast("输入发布卖单数量");
      this.bloading = true;
      this.$post({
        loading: true,
        url: "/trade_order/publish",
        data: { num: this.send_num },
        success: (res) => {
          this.getdata();
          this.$toast({
            message: "发布成功",
            duration: 2500,
            onClose: () => {
              this.send_show = false;
              this.bloading = false;
              this.page = 1;
              this.list = [];
              this.finished = false;
              this.onLoad();
            },
          });
        },
        tip: () => {
          this.bloading = false;
        },
      });
    },
    onRefresh() {
      this.page = 1;
      this.list = [];
      this.finished = false;
      this.onLoad();
      this.getdata();
      setTimeout(() => {
        this.refreshing = false;
      }, 2000);
    },
  },
};
</script>
<style lang='less' scoped>
.bodybox {
  width: 100%;
  padding: 15px;
  .num {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      width: 59%;
      p {
        &:nth-child(2) {
          color: #24e5a0;
          font-size: 24px;
          font-weight: 550;
          margin-top: 10px;
        }
      }
    }
    img {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }
  }
  .buy {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      width: 100%;
      padding: 14px;
      background: linear-gradient(
        328deg,
        #24809b 0%,
        #1b2230 93%,
        #1b1f2e 100%
      );
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3c4e60;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 12px;
        &:nth-child(1) {
          color: #24e5a0;
          font-size: 15px;
          font-weight: 550;
          margin-bottom: 5px;
        }
      }
    }
  }
  .tit {
    width: 100%;
    padding: 15px 0;
    font-size: 16px;
    font-weight: 550;
  }
  .item {
    width: 100%;
    background: #110f20;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #3c4e60;
    padding: 15px;
    margin-bottom: 15px;
    .info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
        border-radius: 50%;
      }
      p {
        flex: 1;
        padding: 0 15px;
      }
      /deep/.van-button {
        .van-button__text {
          font-size: 12px;
        }
      }
    }
    .nums {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      > div {
        width: 48%;
        background: rgba(255, 255, 255, 0.09);
        border-radius: 4px 4px 4px 4px;
        padding: 12px 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:nth-child(1) {
          p {
            color: #999999;
            font-size: 12px;
            &:nth-child(1) {
              color: #1ae8a0;
              font-size: 14px;
              font-weight: 550;
              margin-bottom: 5px;
            }
          }
        }
        &:nth-child(2) {
          p {
            color: #999999;
            font-size: 12px;
            &:nth-child(1) {
              color: #fff;
              font-size: 14px;
              font-weight: 550;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    .time {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #999999;
        font-size: 12px;
        &:nth-child(2) {
          color: #ffffff;
        }
      }
    }
  }
}
.popupbox {
  width: 78%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(32deg, #24809b 0%, #1b2230 93%, #1b1f2e 100%);
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #3c4e60;
  padding: 16px;
  .content {
    width: 100%;
    .tit {
      width: 100%;
      text-align: center;
      font-size: 16px;
      margin-bottom: 26px;
    }
    /deep/.van-field {
      background: #110f20;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3c4e60;
      .van-field__button {
        color: #1ae8a0;
      }
    }
    .e {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px;
      p {
        font-size: 12px;
        span {
          color: #1ae8a0;
        }
      }
    }
    .n {
      width: 100%;
      font-size: 12px;
      padding: 10px;
      span {
        color: #1ae8a0;
      }
    }
    .btns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /deep/.van-button {
        width: 120px;
        height: 40px;
      }
    }
  }
}

//冰锥
.icce {
  width: 92vw;
  //height: 69vw;
  background: linear-gradient(328deg, #24809b 0%, #1b2230 93%, #1b1f2e 100%);
  border-radius: 3vw;
  margin: 0 auto;
  overflow: hidden;

  margin-bottom: 5vw;
  .ice {
    width: 92vw;
    height: 10vw;
    margin: 0 auto;
    border-radius: 3vw;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.wkf {
  width: 32vw;
  height: 32vw;
  margin: 0 auto;
  margin-top: 21vw;
  img {
    width: 100%;
    height: 100%;
  }
}
.wk {
  height: 6vw;
  font-size: 4vw;
  margin-top: 8vw;
  margin-bottom: 26vw;
  text-align: center;
  font-weight: bold;
  line-height: 6vw;
  color: #24809b;
}
</style>