import axios from 'axios';
import router from '../router'
import qs from 'qs';
import vant from 'vant';
import { Toast } from 'vant';
import JSONBIG from 'json-bigint';
import VConsole from 'vconsole'
import plugins from '../plugins'
import regular from '../utils/regular'
export default {
  install: Vue => {
    Vue.use(vant)
    Vue.use(plugins)
    if (process.env.VUE_APP_IS_LOG == 1) {
      const vConsole = new VConsole()
      Vue.use(vConsole)
    }
    Vue.prototype.$rg = regular //部分正则
    Vue.prototype.$axios = axios
    // axios.defaults.withCredentials = true
    // js返回进度丢失
    axios.defaults.transformResponse = [
      function (data) {
        const json = JSONBIG({ storeAsString: true })
        const res = json.parse(data)
        return res
      }
    ]
    axios.defaults.timeout = 30000000; //请求超时
    const no_login_code = -1;       //未登录 状态码
    const success_code = 1;         //成功 状态码
    const err_code = 0;             //失败 状态码
    const token_headers = true      // true->token headers 传值

    console.warn(`%c ${process.env.NODE_ENV}`, 'background:#328ee6;padding:0 5px 1px 0;border-radius: 3px 0 0 3px;  color: #ffffff')
    if (process.env.NODE_ENV == 'development') setpath('https://long.ychjyx.club')
    else if (process.env.NODE_ENV == 'production') setpath('https://api.dangmie.cn')
    function setpath(api_path = '', web_path = api_path, ws_path = '') {
      axios.defaults.baseURL = `${api_path}/api/v1`;
      Vue.prototype.httpPath = web_path;
      Vue.prototype.wsPath = ws_path;
      console.log(`%c token headers ：${token_headers}`, 'background:#328ee6;padding:0 5px 1px 0;border-radius: 3px 0 0 3px;  color: #ffffff')
      api_path && console.log(`%c api_path ${api_path}`, 'background:#328ee6;padding:0 5px 1px 0;border-radius: 3px 0 0 3px;  color: #ffffff')

      web_path && console.log(`%c web_path ${web_path}`, 'background:#328ee6;padding:0 5px 1px 0;border-radius: 3px 0 0 3px;  color: #ffffff')

      ws_path && console.log(`%c ws_path ${ws_path}`, 'background:#328ee6;padding:0 5px 1px 0;border-radius: 3px 0 0 3px;  color: #ffffff')
    }
    Vue.prototype.$get = params => {
      const token = Vue.prototype.session.get("token") || "";
      let data
      params.loading && Toast.loading({
        forbidClick: true,
        duration: 0
      });
      if (token_headers) {
        data = {
          params: Object.assign({}, params.data),
          headers: {
            'authorization': `Bearer ${token}`
          }
        }
      } else {
        data = {
          params: Object.assign({}, params.data, token ? { token } : '')
        }
      }
      axios.get(params.url, data).then(res => {
        params.loading && Toast.clear();
        if (res.data.code == no_login_code) {
          if (token) Vue.prototype.session.del("token");
          Toast({
            message: '未登录',
            onClose: () => {
              router.replace('/')
            }
          });
        } else if (res.data.code == success_code) {
          params.success && params.success(res.data);
        } else if (res.data.code == err_code) {
          if (params.tip) {
            params.tip(res.data)
            Toast(res.data.msg || res.data.message);
          } else if (params.tips) {
            params.tips(res.data)
          }
        }
      }).catch(err => {
        console.log('请求失败', err.response)
        err.response && Toast(`${err.response.config.url} ${err.response.status}`);
      })
    }
    Vue.prototype.$del = params => {
      const token = Vue.prototype.session.get("token") || "";
      let data
      params.loading && Toast.loading({
        forbidClick: true,
        duration: 0
      });
      if (token_headers) {
        data = {
          params: Object.assign({}, params.data),
          headers: {
            'authorization': `Bearer ${token}`
          }
        }
      } else {
        data = {
          params: Object.assign({}, params.data, token ? { token } : '')
        }
      }
      axios.delete(params.url, data).then(res => {
        params.loading && Toast.clear();
        if (res.data.code == no_login_code) {
          if (token) Vue.prototype.session.del("token");
          Toast({
            message: '未登录',
            onClose: () => {
              router.replace('/')
            }
          });
        } else if (res.data.code == success_code) {
          params.success && params.success(res.data);
        } else if (res.data.code == err_code) {
          if (params.tip) {
            params.tip(res.data)
            Toast(res.data.msg || res.data.message);
          } else if (params.tips) {
            params.tips(res.data)
          }
        }
      }).catch(err => {
        console.log('请求失败', err.response)
        err.response && Toast(`${err.response.config.url} ${err.response.status}`);
      })
    }
    Vue.prototype.$post = params => {
      const token = Vue.prototype.session.get("token") || "";
      let data = {}
      let headers = {}
      params.loading && Toast.loading({
        forbidClick: true,
        duration: 0
      });
      if (token_headers) {
        headers['authorization'] = `Bearer ${token}`
        if (params.upload) {
          headers['Content-Type'] = 'multipart/form-data';
          data = params.data
        } else if (params.noToken) {
          headers = {}
          data = params.data  //有时候java传值方式
          // data = qs.stringify(Object.assign({}, params.data))
        } else if (params.raw) {
          headers['Content-Type'] = 'multipart/form-data';
          data = params.data
        } else {
          // data = params.data //有时候java传值方式
          data = qs.stringify(Object.assign({}, params.data))
        }
      } else {
        if (params.upload) {
          data = params.data.append('token', token ? token : "")
          headers['Content-Type'] = 'multipart/form-data'
        } else if (params.noToken) {
          data = qs.stringify(Object.assign({}, params.data))
        } else {
          data = qs.stringify(Object.assign({}, params.data, token ? { token } : {}))
        }
      }
      axios.post(params.url, data, { headers }).then(res => {
        params.loading && Toast.clear();
        if (res.data.code == no_login_code) {
          if (token) Vue.prototype.session.del("token");
          Toast({
            message: '未登录',
            onClose: () => {
              router.replace('/')
            }
          });
        } else if (res.data.code == success_code) {
          params.success && params.success(res.data);
        } else if (res.data.code == err_code) {
          if (params.tip) {
            params.tip(res.data)
            Toast(res.data.msg || res.data.message);
          } else if (params.tips) {
            params.tips(res.data)
          }
        }
      }).catch(err => {
        console.log('请求失败', err.response)
        err.response && Toast(`${err.response.config.url} ${err.response.status}`);
      })
    }
    Vue.prototype.$put = params => {
      const token = Vue.prototype.session.get("token") || "";
      let data = {}
      let headers = {}
      params.loading && Toast.loading({
        forbidClick: true,
        duration: 0
      });
      if (token_headers) {
        headers['authorization'] = `Bearer ${token}`
        if (params.upload) {
          headers['Content-Type'] = 'multipart/form-data';
          data = params.data
        } else if (params.noToken) {
          headers = {}
          data = params.data  //有时候java传值方式
          // data = qs.stringify(Object.assign({}, params.data))
        } else if (params.raw) {
          headers['Content-Type'] = 'multipart/form-data';
          data = params.data
        } else {
          // data = params.data //有时候java传值方式
          data = qs.stringify(Object.assign({}, params.data))
        }
      } else {
        if (params.upload) {
          data = params.data.append('token', token ? token : "")
          headers['Content-Type'] = 'multipart/form-data'
        } else if (params.noToken) {
          data = qs.stringify(Object.assign({}, params.data))
        } else {
          data = qs.stringify(Object.assign({}, params.data, token ? { token } : {}))
        }
      }
      axios.put(params.url, data, { headers }).then(res => {
        params.loading && Toast.clear();
        if (res.data.code == no_login_code) {
          if (token) Vue.prototype.session.del("token");
          Toast({
            message: '未登录',
            onClose: () => {
              router.replace('/')
            }
          });
        } else if (res.data.code == success_code) {
          params.success && params.success(res.data);
        } else if (res.data.code == err_code) {
          if (params.tip) {
            params.tip(res.data)
            Toast(res.data.msg || res.data.message);
          } else if (params.tips) {
            params.tips(res.data)
          }
        }
      }).catch(err => {
        console.log('请求失败', err.response)
        err.response && Toast(`${err.response.config.url} ${err.response.status}`);
      })
    }
  }
}