<template>
  <div class='indexbox'>
    <nav-bar title="团队收益" left-arrow />
    <div class="bodybox">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="item" v-for="val in list" :key="val.id">
          <div class="left">
            <p>{{ val.trend_desc }}</p>
            <p>{{ val.created_at }}</p>
          </div>
          <p class="right">{{ val.num }}LONGKA</p>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        list: [],
        page: 1,
        loading: false,
        finished: false,
      }
    },
    created() { },
    methods: {
      onLoad() {
        let page = this.page++
        this.$get({
          url: "/credit_log/reward_log",
          data: {
            type: 2,
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  /deep/.van-sticky--fixed {
    width: 100%;
    padding: 15px 15px 0;
    background: #0D081A;
  }
  .bodybox {
    width: 100%;
    padding: 15px;
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 15px;
      background: #110F20;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3C4E60;
      margin-bottom: 15px;
      .left {
        width: 60%;
        p {
          width: 100%;
          font-size: 15px;
          line-height: 1.3;
          &:nth-child(2) {
            line-height: 1;
            color: #999999;
            font-size: 13px;
            margin-top: 12px;
          }
        }
      }
      .right {
        width: 39%;
        text-align: right;
        color: #24E5A0;
        font-weight: 550;
        font-size: 20px;
        margin-top: 6px;
      }
    }
  }
</style>