<template>
  <div class='indexbox'>
    <nav-bar title="兑换LONGKA" left-arrow right-text="兑换记录" @click-right="router('ceaChangelist')" />
    <div class="bodybox">
      <div class="top">
        <div class="num">
          <p :class="{ act: index == item }" v-for="(item, ind) in selects" :key="ind" @click="change(item)">{{ item }}
          </p>
        </div>
        <p class="tit">兑换数量</p>
        <van-field v-model="num" center clearable placeholder="请输入兑换数量" :border=false @input="inputLONGKA" type="number">
          <template #button>
            <p>≈{{ cea_to_bnb }}BNB</p>
          </template>
        </van-field>
      </div>
      <div class="bottom">
        <p class="tit">兑换说明</p>
        <div class="content">{{ info.info }}</div>
        <van-button block type="primary" :loading="loading" @click="exchange">确认兑换</van-button>
      </div>
    </div>
  </div>
</template>
<script>
  import { debounce } from 'lodash'
  export default {
    data() {
      return {
        info: {},
        selects: [],
        index: '',
        num: '',
        cea_to_bnb: 0,
        loading: false,
      }
    },
    created() {
      this.getinfo()
    },
    mounted() { },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/exchange_info",
          success: (res) => {
            this.info = res.data
            this.selects = res.data.selects
          },
          tip: () => { },
        });
      },
      change(index) {
        this.index = index
        this.num = index
        this.getbnb()
      },
      // input防抖
      inputLONGKA: debounce(function () {
        if (!/^\d+(\.\d{1,4})?$/.test(Number(this.num))) {
          this.num = this.toFixed(this.num, 4, false)
          this.$toast('兑换数量最多可输入小数点4位')
        } else {
          this.getbnb()
        }
      }, 500),
      getbnb() {
        if (!/^\d+(\.\d{1,4})?$/.test(Number(this.num))) return this.$toast('兑换数量最多可输入小数点4位')
        this.$post({
          loading: true,
          url: "/recharge/cea_to_bnb",
          data: { cea_num: this.num },
          success: (res) => {
            this.cea_to_bnb = Number(res.data)
          },
          tip: () => {
            this.num = ''
            this.cea_to_bnb = 0
          },
        });
      },
      exchange() {
        if (!this.num) return this.$toast('请输入兑换数量')
        if (!/^\d+(\.\d{1,4})?$/.test(Number(this.num))) return this.$toast('兑换数量最多可输入小数点4位')
        if (Number(this.num) < Number(this.info.min)) return this.$toast(`最小兑换数量${this.info.min}`)
        if (!this.cea_to_bnb) return this.$toast('请等待获取兑换BNB数量')
        this.loading = true
        this.$post({
          loading: true,
          url: "/recharge/prepare",
          data: { type: 2, cea_num: this.num },
          success: (res) => {
            this.transfer(res.data.order_id, res.data.bnb_num)
          },
          tip: () => {
            this.loading = false
          },
        });
      },
      transfer(order_id, bnb_num) {
        this.$toast.loading({ forbidClick: true, duration: 0, })
        let that = this
        that.$nextTick(() => {
          that.blockFun
            .transfer('bnb', String(bnb_num), that.info.bnb_in, 'ether', true)
            .then((hash) => {
              that.pay_invest(hash, order_id);
            }).catch((err) => {
              that.$toast.fail(err.msg)
              console.warn('err', err.data);
              that.loading = false
            });
        });
      },
      //hash回调
      pay_invest(hash, order_id) {
        this.$post({
          url: '/recharge/submit',
          loading: true,
          data: { hash, order_id },
          success: res => {
            this.num = ''
            this.cea_to_bnb = 0
            this.$toast({
              message: '兑换成功',
              duration: 4000,
              onClose: () => {
                this.loading = false
              }
            })
          },
          tip: () => {
            this.num = ''
            this.cea_to_bnb = 0
            this.loading = false
          }
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .bodybox {
    width: 100%;
    height: 90vh;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .top {
      width: 100%;
      .num {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        p {
          min-width: 75px;
          padding: 10px 22px;
          background: linear-gradient(328deg, #24809b 0%, #1B2230 93%, #1B1F2E 100%);
          border-radius: 6px 6px 6px 6px;
          border: 1px solid #3C4E60;
          text-align: center;
          color: #FFF;
          font-size: 15px;
          margin: 0 10px 10px 0;
        }
        .act {
          background: linear-gradient(83deg, #24E5A0 0%, #B6F1AB 100%);
          color: #0D081A;
        }
      }
      /deep/.van-field {
        background: #110F20;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #3C4E60;
        .van-field__button {
          p {
            color: #999999;
            font-size: 13px;
          }
        }
      }
    }
    .tit {
      width: 100%;
      padding: 10px 0;
      font-size: 15px;
      font-weight: 550;
    }
    .bottom {
      width: 100%;
      .content {
        max-height: 450px;
        overflow-y: auto;
        color: #999999;
        font-size: 14px;
        margin-bottom: 20px;
        padding: 0 0 10px;
      }
    }
  }
</style>