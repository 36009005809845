<template>
  <div id="app" v-cloak>
    <transition name="fade" mode="out-in">
      <router-view v-if="show" />
    </transition>
    <div class="box" v-if="!show && chain != 56">请切换币安链</div>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      show: true
    };
  },
  methods: {},
  created() {
    // if (process.env.NODE_ENV == 'production') {
    //   this.session.del("token")
    // }
    let that = this
    let web3 = that.$metaMask.getWeb3();
    if (window.ethereum) {
      window.ethereum.request({ method: "eth_chainId" }).then((res) => {
        console.warn('CHAIN_ID', web3.utils.hexToNumberString(res));
        that.chain = web3.utils.hexToNumberString(res)
        if (res != 56) {
          that.show = false
          that.$toast({
            duration: 0,
            type: "fail",
            message: '请切换币安链'
          });
        } else {
          that.show = true
        }
      });
    }
  },
  mounted() {
    let self = this;
    this.$nextTick(() => {
      self.$metaMask.init().then(() => {
        let web3 = self.$metaMask.getWeb3();
        self.$metaMask.getContract(web3, self.$abi);
      });
    })
  }
};
</script>

<style lang="less">
.van-dialog__header,
.van-dialog__message {
  color: #000;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
// 修改van-toast--loading颜色 用就打开注释改 否则忽略
// .van-toast--loading {
//   box-shadow: 0px 1vw 3vw rgba(0, 0, 0, 0.16);
//   background-color: #fff !important;
//   .van-toast__loading {
//     color: #333;
//   }
//   .van-toast__text {
//     color: #333;
//   }
// }
.van-dialog {
  background: #242424 !important;
  box-shadow: 0px 3px 10px 1px rgba(255, 236, 209, 0.13);
  .van-dialog__header {
    color: #fff;
  }
  .van-dialog__message--has-title {
    color: #fff;
  }
  .van-dialog__cancel {
    .van-button__text {
      color: #fff;
    }
  }
}
</style>
<style lang="less" scoped>
.box {
  width: 100%;
  font-size: 15px;
  text-align: center;
}
</style>