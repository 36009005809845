<template>
  <div class='indexbox'>
    <nav-bar title="LONGKA钱包" left-arrow />
    <div class="bodybox">
      <van-sticky offset-top="12vw">
        <div class="num">
          <div class="left">
            <p>LONGKA</p>
            <p>{{ info.longka || 0 }}</p>
          </div>
          <div class="right">
<!--            <div class="btn" @click="router('ceaRecharge')">-->
<!--              <img src="@/assets/img/dh@2x.png" alt="">-->
<!--              <span>充值</span>-->
<!--            </div>-->
            <div class="btn" @click="router('ceaWithdrawal')">
              <span>提现</span>
            </div>
          </div>
        </div>
        <p class="tit">变动记录</p>
      </van-sticky>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="item" v-for="val in list" :key="val.id">
          <div class="left">
            <p>{{ val.trend_desc }}</p>
            <p>{{ val.created_at }}</p>
          </div>
          <p class="right">{{ val.type == 2 ? '-' : '' }}{{ val.num }}</p>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},
        list: [],
        page: 1,
        loading: false,
        finished: false,
      }
    },
    created() {
      this.getinfo()
    },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/user/info",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
      onLoad() {
        let page = this.page++
        this.$get({
          url: "/credit_log/log",
          data: {
            credit: 'credit3',
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  /deep/.van-sticky--fixed {
    width: 100%;
    padding: 15px 15px 0;
    background: #0D081A;
  }
  .bodybox {
    width: 100%;
    padding: 15px;
    .num {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: linear-gradient(32deg, #24809b 0%, #1B2230 93%, #1B1F2E 100%);
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3C4E60;
      padding: 20px;
      .left {
        width: 70%;
        p {
          color: #fff;
          font-size: 13px;
          &:nth-child(2) {
            color: #24E5A0;
            font-weight: 550;
            margin-top: 12px;
            text-align: left;
            font-size: 20px;
          }
        }
      }
      .right {
        width: 28%;
        display: flex;
        flex-direction: column;
        >div {
          background: linear-gradient(94deg, #24E5A0 0%, #B6F1AB 100%);
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 13px;
          img {
            width: 18px;
            height: 18px;
          }
          span {
            font-size: 13px;
            color: #0D081A;
            margin-left: 4px;
            font-weight: 550;
          }
          &:nth-child(2) {
            margin: 8px 0;
          }
        }
      }
    }
    .tit {
      width: 100%;
      padding: 15px 0 12px;
      font-size: 15px;
    }
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 15px;
      background: #110F20;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3C4E60;
      margin-bottom: 15px;
      .left {
        width: 60%;
        p {
          width: 100%;
          font-size: 15px;
          line-height: 1.3;
          &:nth-child(2) {
            line-height: 1;
            color: #999999;
            font-size: 13px;
            margin-top: 12px;
          }
        }
      }
      .right {
        width: 39%;
        text-align: right;
        color: #24E5A0;
        font-weight: 550;
        font-size: 20px;
        margin-top: 6px;
      }
    }
  }
</style>