<template>
  <transition name="van-fade">
    <div class="scrolltop" :style="set_bg_style()" :class="{ open: visible }" @click.stop="handleClick">
      <van-icon name="back-top" :size="size" :color="color" />
    </div>
  </transition>
</template>

<script>
const cubic = (value) => Math.pow(value, 3)
const easeInOutCubic = (value) => value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2
export default {
  props: {
    visibilityHeight: {
      type: Number,
      default: 300,
    },
    target: [String],
    bgColor: {
      type: String,
      default: ''
    },
    round: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '20'
    },
    color: {
      type: String,
      default: '#ffffff'
    },
  },
  data() {
    return {
      el: null,
      container: null,
      visible: false,
    }
  },
  mounted() {
    this.init()
    this.throttledScrollHandler = this._throttle(this.onScroll, 300)
    this.container.addEventListener("scroll", this.throttledScrollHandler)
  },
  methods: {
    _throttle(func, ms = 1000) {
      let canRun = true
      return function (...args) {
        if (!canRun) return
        canRun = false
        setTimeout(() => {
          func.apply(this, args)
          canRun = true
        }, ms)
      }
    },
    init() {
      this.container = document
      this.el = document.documentElement
      if (this.target) {
        this.el = document.querySelector(this.target)
        if (!this.el) {
          throw new Error(`target is not existed: ${this.target}`)
        }
        this.container = this.el
      }
    },
    onScroll() {
      // 这里，如果document.documentElement.scrollTop 的值为0，就获取document.body.scrollTop
      const scrollTop = this.el.scrollTop || document.body.scrollTop
      this.visible = scrollTop >= this.visibilityHeight
    },
    handleClick(e) {
      this.scrollToTop()
      // this.$emit("click", e)
    },
    scrollToTop() {
      // 还有这里，如果document.documentElement.scrollTop 的值为0，就获取document.body元素
      const el = this.el.scrollTop ? this.el : document.body
      const beginTime = Date.now()
      const beginValue = el.scrollTop
      const rAF =
        window.requestAnimationFrame || ((func) => setTimeout(func, 16))
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500
        if (progress < 1) {
          el.scrollTop = beginValue * (1 - easeInOutCubic(progress))
          rAF(frameFunc)
        } else {
          el.scrollTop = 0
        }
      }
      rAF(frameFunc)
    },
    set_bg_style() {
      if (this.bgColor) return `background-color:${this.bgColor}`
      if (this.round) return `border-radius:50%`
    },
  },
  beforeDestroy() {
    this.container.removeEventListener("scroll", this.throttledScrollHandler)
    this.el = null
  },
}
</script>
<style lang="less" scoped>
@top_bg_color: #0f1724; //背景色
.scrolltop {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: -50px;
  border-radius: 0;
  z-index: 99;
  background: @top_bg_color;
  opacity: 0;
  transition: .3s ease;
  &.open {
    opacity: 1;
    right: 20px;
  }
}
</style>