<template>
  <div class='indexbox'>
    <nav-bar title="LONGKA充值" left-arrow right-text="充值记录" @click-right="router('ceaRechargelist')" />
    <div class="bodybox">
      <div class="top">
        <p class="tit">充值数量</p>
        <van-field v-model="num" center clearable placeholder="请输入充值数量" :border=false type="number" @input="inputNum">
          <template #button>
            <p>LONGKA</p>
          </template>
        </van-field>
      </div>
      <div class="bottom">
        <p class="tit">充值说明</p>
        <div class="content">{{ info.info }}</div>
        <van-button block type="primary" :loading="loading" @click="submit">确认充值</van-button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},
        num: '',
        loading: false,
      }
    },
    computed: {},
    watch: {},
    created() {
      this.getinfo()
    },
    mounted() { },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/cea_recharge_info",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
      inputNum() {
        if (!/^\d+(\.\d{1,4})?$/.test(Number(this.num))) {
          this.num = this.toFixed(this.num, 4, false)
          this.$toast('充值数量最多可输入小数点4位')
        }
      },
      submit() {
        if (!this.num) return this.$toast('请输入充值数量')
        if (!/^\d+(\.\d{1,4})?$/.test(Number(this.num))) return this.$toast('充值数量最多可输入小数点4位')
        this.loading = true
        this.$post({
          loading: true,
          url: "/cea_recharge/prepare",
          data: { cea_num: this.num },
          success: (res) => {
            this.transfer(res.data.order_id)
          },
          tip: () => {
            this.loading = false
          },
        });
      },
      transfer(order_id) {
        this.$toast.loading({ forbidClick: true, duration: 0, })
        let that = this
        that.$nextTick(() => {
          that.blockFun
            .transfer('cea', String(that.num), that.info.in, 'ether', false)
            .then((hash) => {
              that.pay_invest(hash, order_id);
            }).catch((err) => {
              that.$toast.fail(err.msg)
              console.warn('err', err.data);
              that.loading = false
            });
        });
      },
      //hash回调
      pay_invest(hash, order_id) {
        this.$post({
          url: '/cea_recharge/submit',
          loading: true,
          data: { hash, order_id },
          success: res => {
            this.num = ''
            this.cea_to_bnb = 0
            this.$toast({
              message: '兑换成功',
              duration: 4000,
              onClose: () => {
                this.loading = false
              }
            })
          },
          tip: () => {
            this.num = ''
            this.cea_to_bnb = 0
            this.loading = false
          }
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .bodybox {
    width: 100%;
    height: 90vh;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .top {
      width: 100%;
      /deep/.van-field {
        background: #110F20;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #3C4E60;
        .van-field__button {
          p {
            color: #24E5A0;
            font-size: 13px;
          }
        }
      }
    }
    .tit {
      width: 100%;
      padding: 10px 0;
      font-size: 15px;
      font-weight: 550;
    }
    .bottom {
      width: 100%;
    }
    .content {
      max-height: 450px;
      overflow-y: auto;
      color: #999999;
      font-size: 14px;
      margin-bottom: 20px;
      padding: 0 0 10px;
    }
  }
</style>