<template>
  <div class='indexbox'>
    <nav-bar title="邀请好友" left-arrow bg-color="none" />
    <div class="titbox">
      <div class="tit">
        <span>{{ shareUrl }}</span>
        <van-button size="small" @click="copyData(shareUrl)">复制链接</van-button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        shareUrl: ''
      }
    },
    computed: {},
    watch: {},
    created() {
      this.getinfo()
    },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/user/share/info",
          success: (res) => {
            this.shareUrl = res.data
          },
          tip: () => { },
        });
      }
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    background: url('../../../assets/img/yqbj@2x.png') no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    .titbox {
      width: 100%;
      padding: 20px 45px;
      position: fixed;
      left: 0;
      top: 58vmax;
      .tit {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        >span {
          width: 70%;
          color: #24E5A0;
          font-size: 15px;
        }
        /deep/.van-button__text {
          font-size: 13px;
        }
      }
    }
  }
</style>