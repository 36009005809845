<template>
  <div class='indexbox'>
    <nav-bar title="公告列表" left-arrow />
    <div class="bodybox">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="item" v-for="val in list" :key="val.id" @click="router(`newdel?id=${val.id}`)">
          <p class="time">{{ val.created_at }}</p>
          <div class="content">
            <p class="one">{{ val.title }}</p>
            <p class="two">{{ val.content_sub }}</p>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  methods: {
    onLoad() {
      let page = this.page++
      this.$get({
        url: "/notice/list",
        data: {
          page,
        },
        success: (res) => {
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>
<style lang='less' scoped>
.bodybox {
  width: 100%;
  padding: 0 15px;
  .item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .time {
      color: #999999;
      font-size: 12px;
      margin: 10px 0;
    }
    .content {
      width: 100%;
      background: #110F20;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3C4E60;
      padding: 15px 15px 10px;
      .one {
        width: 100%;
        font-size: 15px;
        font-weight: 550;
        line-height: 1;
      }
      .two {
        width: 100%;
        margin-top: 8px;
        color: #999999;
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }
}
</style>