<template>
  <div>
    <van-sticky>
      <div class="header">
        <div class="left" @click="show = true">
          <img src="../assets/img/caid@2x.png" alt="">
        </div>
        <div class="right">
          <p class="a" v-if="userInfo.address_show">{{ userInfo.address_show }}</p>
          <p class="a" v-else>请授权登录</p>
        </div>
      </div>
    </van-sticky>

    <van-popup v-model="show" position="left" :style="{ width: '55%', height: '100%' }">
      <div class="navbox">
        <div class="address">
          <div class="head">
            <label for="img" class="imgbox">
              <img v-if="avatar_img" :src="avatar_img" alt="">
              <img v-else src="@/assets/img/toux@2x.png" alt="">
              <!-- <input type="file" accept="image/*" id="img" ref="upfile" style="display: none;" @change="uploadImages"> -->
            </label>
<!--            <p>Lv.{{ userInfo.level_id }}</p>-->
          </div>
          <p class="add">{{ userInfo.address_show }}</p>
          <img src="@/assets/img/fuzhi@2x.png" alt="" @click="copyData(userInfo.address)">
        </div>
        <div class="nav">
          <router-link to="usdtWallet">
            <img src="@/assets/img/us@2x.png" alt="">
            <span>USDT</span>
          </router-link>
          <router-link to="jifenWallet">
            <img src="@/assets/img/dichi@2x.png" alt="">
            <span>积分</span>
          </router-link>
          <router-link to="longkaWallet">
            <img src="@/assets/img/cea@2x.png" alt="">
            <span>LONGKA</span>
          </router-link>
          <!-- <router-link to="tran">
            <img src="@/assets/img/jy@2x.png" alt="">
            <span>C2C交易</span>
          </router-link>
          <router-link to="tranlist">
            <img src="@/assets/img/jiaoyijilu.png" alt="">
            <span>交易记录</span>
          </router-link>-->
          <router-link to="products">
            <img src="@/assets/img/kaungji.png" alt="">
            <span>产品中心</span>
          </router-link>
          <router-link to="product_orders">
            <img src="@/assets/img/order.png" alt="">
            <span>产品订单</span>
          </router-link>
          <router-link to="share">
            <img src="@/assets/img/yq@2x.png" alt="">
            <span>邀请好友</span>
          </router-link>
        </div>
        <!-- <router-link to="tranlist" class="na">
          <img src="@/assets/img/jyjl@2x.png" alt="">
          <p class="one">交易记录</p>
          <img src="@/assets/img/mo.png" alt="">
        </router-link> -->
<!--        <router-link to="team" class="na">-->
<!--          <img src="@/assets/img/td@2x.png" alt="">-->
<!--          <p class="one">我的团队</p>-->
<!--          <img src="@/assets/img/mo.png" alt="">-->
<!--        </router-link>-->
<!--        <router-link to="sharelist" class="na">-->
<!--          <img src="@/assets/img/tjsy@2x.png" alt="">-->
<!--          <p class="one">推荐收益</p>-->
<!--          <img src="@/assets/img/mo.png" alt="">-->
<!--        </router-link>-->
<!--        <router-link to="tlist" class="na">-->
<!--          <img src="@/assets/img/tdsy@2x.png" alt="">-->
<!--          <p class="one">团队收益</p>-->
<!--          <img src="@/assets/img/mo.png" alt="">-->
<!--        </router-link>-->
        <!-- <div @click="logout" class="na logout">
          <img src="../assets/img/tuichu@2x.png" alt="">
          <p class="one">退出</p>
          <img src="@/assets/img/mo.png" alt="">
        </div> -->
      </div>
    </van-popup>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        show: false,
        active_name: '/',
        userInfo: {},
        avatar_img: '',
      }
    },
    props: {
      info: {
        type: Object,
        default: () => { }
      }
    },
    watch: {
      info: {
        handler(data) {
          this.userInfo = data
          this.avatar_img = data.avatar_img
        },
        immediate: true
      }
    },
    methods: {
      invest() {
        this.$emit('invest')
      },
      logout() {
        this.session.del("token")
        this.show = false
      },
      uploadImages() {
        const dom = this.$refs.upfile
        const data = dom.files[0]
        if (!data) return
        let file = new FormData()
        file.append("file", data)
        this.$post({
          loading: true,
          url: "/upload/local/image",
          loading: true,
          upload: true,
          data: file,
          success: (res) => {
            this.editAvatar(res.data)
          },
          tip: () => { },
        });
      },
      editAvatar(url) {
        this.$post({
          loading: true,
          url: "/user/edit_avatar",
          data: { url },
          success: (res) => {
            this.avatar_img = res.data.url
          },
          tip: () => { },
        });
      }
    },
  }
</script>
<style lang='less' scoped>
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 15px;
    .left {
      display: flex;
      align-items: center;
      img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
      }
      font-size:20px;
      font-weight: 550;
    }
    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .a {
        background: #193E3E;
        border-radius: 17px;
        color: #24809b;
        font-size: 13px;
        padding: 5px 12px;
      }
    }
  }
  /deep/.van-overlay {
    background-color: rgba(15, 15, 15, .3) !important;
    z-index: 100 !important;
  }
  /deep/.van-popup {
    width: 100%;
    background: url('../assets/img/di@2x.png') no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    background-position: top center;
    background-color: #0D081A;
    z-index: 100 !important;
    .navbox {
      width: 100%;
      height: 96vh;
      padding: 55px 15px 30px;
      .address {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .head {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          width: 48px;
          height: 48px;
          .imgbox {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            overflow: hidden;
            >img {
              width: 45px;
              height: 45px;
              object-fit: contain;
            }
          }
          p {
            width: 100%;
            position: absolute;
            bottom: 0;
            background: #241F30;
            border-radius: 20px;
            border: 1px solid #FFFFFF;
            font-size: 12px;
            text-align: center;
            line-height: 1;
            padding: 2px 8px;
          }
        }
        .add {
          font-size: 13px;
        }
        >img {
          width: 14px;
          height: 14px;
        }
      }
      .nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 10px 0;
        >a {
          width: 83px;
          height: 71px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background: rgba(255, 255, 255, 0.09);
          border-radius: 4px 4px 4px 4px;
          img {
            width: 22px;
            height: 22px;
            margin-bottom: 10px;
          }
          span {
            font-size: 13px;
            color: #FFFFFF;
          }
        }
      }
      .na {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 8px 0;
        img {
          &:nth-child(1) {
            width: 16px;
            height: 16px;
          }
          &:nth-child(3) {
            width: 10px;
            height: 10px;
          }
        }
        p {
          flex: 1;
          font-size: 12px;
          color: #FFFFFF;
          padding: 0 8px;
        }
      }
      .logout {
        padding: 10px;
        background: rgba(255, 255, 255, 0.09);
        border-radius: 4px 4px 4px 4px;
      }
    }
  }
</style>
