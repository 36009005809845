<template>
  <div class='indexbox'>
    <nav-bar title="提现记录" left-arrow />
    <div class="bodybox">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="item" v-for="val in list" :key="val.id">
          <div><span class="l">提现金额</span><span class="n">{{ val.num }}USDT</span></div>
          <!-- <div><span class="l">手续费</span><span class="r">100</span></div> -->
          <div><span class="l">实际到账</span><span class="r">{{ val.real_amount }}</span></div>
          <div><span class="l">提现时间</span><span class="t">{{ val.created_at }}</span></div>
          <div><span class="l">提现到账状态</span>
            <p>
              <span class="e" v-if="val.status == 3">已驳回</span>
              <span class="g" v-if="val.status == 2">已到账</span>
              <span class="y" v-if="val.status == 0 || val.status == 1">处理中</span>
            </p>
          </div>
          <div v-if="val.status == 3"><span class="l">驳回原因</span><span class="r">{{ val.remark }}</span></div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    onLoad() {
      let page = this.page++
      this.$get({
        url: "/withdrawal/log",
        data: { page },
        success: (res) => {
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>
<style lang='less' scoped>
.bodybox {
  width: 100%;
  padding: 15px;
  .item {
    background: #110F20;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #3C4E60;
    margin-bottom: 15px;
    padding: 15px 15px 3px;
    >div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 12px;
      .l {
        width: 29%;
      }
      >span {
        color: #fff;
        font-size: 14px;
        &:nth-child(2) {
          width: 70%;
          text-align: right;
        }
      }
      .n {
        color: #24E5A0;
        font-size: 15px;
        font-weight: 550;
      }
      .t {
        font-size: 13px;
        color: #999999;
      }
      p {
        span {
          font-size: 12px;
          padding: 2px 10px 3px;
          border-radius: 15px;
        }
      }
      .e {
        background: rgba(221, 85, 85, 0.18);
        border: 1px solid #DD5555;
        color: #DD5555;
      }
      .g {
        background: rgba(85, 221, 132, 0.18);
        border: 1px solid #55DD84;
        color: #55DD84;
      }
      .y {
        background: rgba(221, 179, 85, 0.18);
        border: 1px solid #DDB355;
        color: #DDB355;
      }
    }
  }
}
</style>