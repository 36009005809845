<template>
  <div class='indexbox'>
    <nav-bar title="USDT提现" left-arrow right-text="提现记录" @click-right="router('usdtWithdrawallist')" />
    <div class="bodybox">
      <div class="num">
        <p>USDT可用余额</p>
        <p>{{ user.usdt || 0 }}</p>
      </div>
      <p class="tit">提现数量</p>
      <van-field v-model="num" center clearable placeholder="请输入提现数量" :border=false type="number" @input="inputNum">
        <template #button>
          <p>手续费{{ info.withdraw_fee || 0 }}%</p>
        </template>
      </van-field>
      <p class="tit">提现地址</p>
      <van-field v-model="user.address" type="textarea" center readonly :border=false />
      <p class="tit">实际到账</p>
      <van-field v-model="getnum" center readonly placeholder="自动显示" :border=false />
      <p class="tit">提现说明</p>
      <div class="content">{{ info.withdraw_info }}</div>

      <van-button block type="primary" :loading="loading" @click="submit">确认提现</van-button>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        user: {},
        info: {},
        num: '',
        loading: false,
      }
    },
    computed: {
      getnum: {
        get: function () {
          if (this.num) {
            let num = Number(this.num) * (100 - Number(this.info.withdraw_fee)) / 100
            return Number(num).toFixed(4)
          }
          return 0
        },
        set: function () { },
      }
    },
    created() {
      this.getuser()
      this.getinfo()
    },
    methods: {
      getuser() {
        this.$get({
          loading: true,
          url: "/user/info",
          success: (res) => {
            this.user = res.data
          },
          tip: () => { },
        });
      },
      getinfo() {
        this.$get({
          loading: true,
          url: "/pay_info",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
      inputNum() {
        if (!/^\d+(\.\d{1,4})?$/.test(Number(this.num))) {
          this.num = this.toFixed(this.num, 4, false)
          this.$toast('提现数量最多可输入小数点4位')
        }
      },
      submit() {
        if (!this.num) return this.$toast('请输入提现数量')
        if (!/^\d+(\.\d{1,4})?$/.test(Number(this.num))) return this.$toast('提现数量最多可输入小数点4位')
        if (Number(this.num) < Number(this.info.withdraw_min)) return this.$toast(`最小提现数量${this.info.withdraw_min}`)
        if (Number(this.num) > Number(this.info.withdraw_max)) return this.$toast(`最大提现数量${this.info.withdraw_max}`)
        this.loading = true
        this.$post({
          loading: true,
          url: "/withdrawal/submit",
          data: { num: this.num },
          success: (res) => {
            this.num = ''
            this.$toast({
              message: '提现成功',
              duration: 4000,
              onClose: () => {
                this.loading = false
                this.getuser()
              }
            })
          },
          tip: () => {
            this.loading = false
          },
        });
      }
    },
  }
</script>
<style lang='less' scoped>
  .bodybox {
    width: 100%;
    padding: 15px;
    .num {
      width: 100%;
      background: linear-gradient(32deg, #24809b 0%, #1B2230 93%, #1B1F2E 100%);
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3C4E60;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 27px 15px;
      p {
        color: #fff;
        font-size: 14px;
        &:nth-child(2) {
          margin-top: 10px;
          color: #24E5A0;
          font-weight: 550;
          font-size: 20px;
        }
      }
    }
    /deep/.van-field {
      background: #110F20;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3C4E60;
      .van-field__button {
        p {
          color: #24E5A0;
          font-size: 13px;
        }
      }
    }
    .tit {
      width: 100%;
      padding: 13px 0;
      font-size: 15px;
      font-weight: 550;
    }
    .content {
      max-height: 180px;
      height: 100px;
      overflow-y: auto;
      color: #999999;
      font-size: 14px;
      margin-bottom: 20px;
      padding: 0 0 10px;
    }
  }
</style>