<template>
  <div class="indexbox">
    <nav-bar title="产品中心" left-arrow />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="bodybox">
        <div style="height: 5vw"></div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="0"
        >
          <div class="item" v-for="val in list" :key="val.id">
            <div class="left_item">
              <img :src="val.img_url" alt="" />
              <div style="width: 3vw"></div>
              <div class="left_item_box">
                <p class="name">{{ val.name }}</p>
                <span class="chanchu">每日产出{{ val.every_day }}底池币</span>
                <div style="height: 1vw"></div>
                <p class="price">USDT: {{ val.price }}</p>
                <div style="height: 1vw"></div>
                <p class="price2">积分: {{ val.jifen }}</p>
              </div>
            </div>
            <div style="height: 1vh"></div>
            <div class="item_bottom">
              <span class="zhouqi"> 周期：{{ val.cycle }} 天 </span>
              <van-button size="small" @click="submit(val)"
                >立即兑换</van-button
              >
            </div>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import router from "../../../router/index";
export default {
  components: {},
  data() {
    return {
      user: {},
      info: {},
      list: [],
      page: 1,
      loading: false,
      finished: false,
      id: "",
      show: false,
      aloading: false,
      send_show: false,
      bloading: false,
      refreshing: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onLoad() {
      let page = this.page++;
      this.$get({
        url: "/product/lists",
        data: {
          page,
        },
        success: (res) => {
          if (!res.data || res.data.data.length == 0) {
            this.finished = true;
          } else {
            var list = res.data.data;
            if (page == 1) {
              this.list = list;
            } else {
              for (var i in list) {
                this.list.push(list[i]);
              }
            }
          }
          this.loading = false;
        },
        tip: () => {
          this.finished = true;
        },
      });
    },
    // 兑换
    submit(val) {
      this.id = val.id;
      this.aloading = true;
      this.$post({
        loading: true,
        url: "/product_order/submit",
        data: { id: this.id },
        success: (res) => {
          this.num = "";
          this.id = "";
          this.$toast({
            message: "兑换成功",
            duration: 2500,
            onClose: () => {
              this.show = false;
              this.aloading = false;
              this.page = 1;
              this.list = [];
              this.finished = false;
              this.onLoad();
            },
          });
          setTimeout(function () {
            router.replace("product_orders");
          }, 1000);
        },
        tip: () => {
          this.aloading = false;
        },
      });
    },
    onRefresh() {
      this.page = 1;
      this.list = [];
      this.finished = false;
      this.onLoad();
      setTimeout(() => {
        this.refreshing = false;
      }, 2000);
    },
  },
};
</script>
<style lang='less' scoped>
.bodybox {
  width: 100%;
  padding: 15px;
  .num {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      width: 59%;
      p {
        &:nth-child(2) {
          color: #24e5a0;
          font-size: 24px;
          font-weight: 550;
          margin-top: 10px;
        }
      }
    }
    img {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }
  }
  .buy {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      width: 48%;
      padding: 14px;
      background: linear-gradient(
        328deg,
        #24809b 0%,
        #1b2230 93%,
        #1b1f2e 100%
      );
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3c4e60;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 12px;
        &:nth-child(1) {
          color: #24e5a0;
          font-size: 15px;
          font-weight: 550;
          margin-bottom: 5px;
        }
      }
    }
  }
  .tit {
    width: 100%;
    padding: 15px 0;
    font-size: 16px;
    font-weight: 550;
  }
  .item {
    width: 100%;
    background: #110f20;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #3c4e60;
    padding: 15px;
    margin-bottom: 15px;
    .left_item {
      display: flex;
      justify-content: left;
      img {
        width: 40%;
        height: 100%;
      }
      .left_item_box {
        flex: 1 1 0%;
        .name {
          font-size: 18px;
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
        .chanchu {
          text-decoration: none;
          font-weight: normal;
          overflow-wrap: normal;
          font-size: 14px;
          color: #4285f3;
        }
        .price {
          text-decoration: none;
          font-weight: bold;
          overflow-wrap: normal;
          font-size: 15px;
          color: rgb(16, 181, 156);
        }
        .price2 {
          text-decoration: none;
          font-weight: bold;
          overflow-wrap: normal;
          font-size: 15px;
          color: rgb(206, 109, 45);
        }
      }
    }
    .item_bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .zhouqi {
      font-weight: bold;
      overflow-wrap: normal;
      font-size: 14px;
      color: rgb(232, 190, 37);
      display: flex;
      justify-content: left;
    }
  }
}
.popupbox {
  width: 78%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(32deg, #24809b 0%, #1b2230 93%, #1b1f2e 100%);
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #3c4e60;
  padding: 16px;
  .content {
    width: 100%;
    .tit {
      width: 100%;
      text-align: center;
      font-size: 16px;
      margin-bottom: 26px;
    }
    /deep/.van-field {
      background: #110f20;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3c4e60;
      .van-field__button {
        color: #1ae8a0;
      }
    }
    .e {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px;
      p {
        font-size: 12px;
        span {
          color: #1ae8a0;
        }
      }
    }
    .n {
      width: 100%;
      font-size: 12px;
      padding: 10px;
      span {
        color: #1ae8a0;
      }
    }
    .btns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /deep/.van-button {
        width: 120px;
        height: 40px;
      }
    }
  }
}

//冰锥
.icce {
  width: 92vw;
  //height: 69vw;
  background: linear-gradient(328deg, #24809b 0%, #1b2230 93%, #1b1f2e 100%);
  border-radius: 3vw;
  margin: 0 auto;
  overflow: hidden;

  margin-bottom: 5vw;
  .ice {
    width: 92vw;
    height: 10vw;
    margin: 0 auto;
    border-radius: 3vw;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.wkf {
  width: 32vw;
  height: 32vw;
  margin: 0 auto;
  margin-top: 21vw;
  img {
    width: 100%;
    height: 100%;
  }
}
.wk {
  height: 6vw;
  font-size: 4vw;
  margin-top: 8vw;
  margin-bottom: 26vw;
  text-align: center;
  font-weight: bold;
  line-height: 6vw;
  color: #24809b;
}
</style>