<template>
  <div class='indexbox'>
    <nav-bar title="我的团队" left-arrow />
    <div class="bodybox">
      <div class="info">
        <div class="headbox">
          <div class="head">
            <img v-if="info.avatar_img" :src="info.avatar_img" alt="">
            <img v-else src="@/assets/img/toux@2x.png" alt="">
            <p>Lv.{{ info.level_id || 0 }}</p>
          </div>
          <p class="add">{{ info.address }}</p>
        </div>
        <div class="num">
          <div>
            <p>{{ info.direct_num || 0 }}</p>
            <p>我的直推人数</p>
          </div>
          <div>
            <p>{{ info.score || 0 }}</p>
            <p>我的BNB业绩</p>
          </div>
          <div>
            <p>{{ info.team_num || 0 }}</p>
            <p>我的团队人数</p>
          </div>
          <div>
            <p>{{ info.team_score || 0 }}</p>
            <p>我的团队BNB业绩</p>
          </div>
        </div>
      </div>
      <div class="tits">
        <img src="@/assets/img/tuijianren@2x.png" alt=""><span>我的推荐人信息</span>
      </div>
      <div class="info">
        <div class="headbox" style="padding-bottom: 10px;" v-if="parent?.address">
          <div class="head">
            <img v-if="parent.avatar_img" :src="parent.avatar_img" alt="">
            <img v-else src="@/assets/img/toux@2x.png" alt="">
            <p>Lv.{{ parent.level_id || 0 }}</p>
          </div>
          <p class="add">{{ parent.address }}</p>
        </div>
        <div class="headbox" style="padding-bottom: 10px;" v-else>
          <p>暂无推荐人信息</p>
        </div>
      </div>
      <div class="tits">
        <img src="@/assets/img/zhitui@2x.png" alt=""><span>我的直推列表</span>
      </div>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="item" v-for="val in list" :key="val.id">
          <div class="headbox" style="padding-bottom: 10px;">
            <div class="head">
              <img v-if="val.avatar_img" :src="val.avatar_img" alt="">
              <img v-else src="@/assets/img/toux@2x.png" alt="">
              <p>Lv.{{ val.level_id || 0 }}</p>
            </div>
            <p class="add">{{ val.account_show }}</p>
          </div>
          <div class="num">
            <span>贡献BNB</span>
            <span>{{ val.score }}</span>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},
        parent: {},
        list: [],
        page: 1,
        loading: false,
        finished: false,
      }
    },
    computed: {},
    watch: {},
    created() {
      this.getinfo()
    },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/user/team/info",
          success: (res) => {
            this.info = res.data
            this.parent = res.data.parent
          },
          tip: () => { },
        });
      },
      onLoad() {
        let page = this.page++
        this.$get({
          url: "/user/direct/info",
          data: {
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .bodybox {
    width: 100%;
    padding: 15px;
    .info {
      width: 100%;
      background: #110F20;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3C4E60;
      padding: 15px 15px 5px;
      .headbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .head {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          width: 50px;
          height: 58px;
          img {
            width: 50px;
            height: 50px;
            object-fit: contain;
            border-radius: 50%;
          }
          p {
            width: 100%;
            position: absolute;
            bottom: 0;
            background: #241F30;
            border-radius: 20px;
            border: 1px solid #FFFFFF;
            font-size: 12px;
            text-align: center;
            line-height: 1;
            padding: 2px 8px 3px;
          }
        }
        .add {
          flex: 1;
          padding: 0 10px;
          font-size: 15px;
          font-weight: 550;
        }
      }
      .num {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;
        >div {
          width: 152px;
          min-height: 75px;
          background: #272635;
          border-radius: 6px;
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p {
            font-size: 12px;
            &:nth-child(1) {
              color: #24E5A0;
              font-size: 15px;
              font-weight: 550;
              margin-bottom: 5px;
              line-height: 1.4;
            }
          }
        }
      }
    }
    .tits {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px 0;
      img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
      span {
        font-size: 16px;
        font-weight: 550;
      }
    }
    .item {
      width: 100%;
      background: #110F20;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3C4E60;
      margin-bottom: 15px;
      .headbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        .head {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          width: 48px;
          height: 43px;
          img {
            width: 30px;
            height: 30px;
          }
          p {
            width: 100%;
            position: absolute;
            bottom: 0;
            background: #241F30;
            border-radius: 20px;
            border: 1px solid #FFFFFF;
            font-size: 12px;
            text-align: center;
            line-height: 1;
            padding: 2px 8px;
          }
        }
        .add {
          flex: 1;
          padding: 0 10px;
          font-size: 13px;
        }
      }
      .num {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #272635;
        border-radius: 6px 6px 6px 6px;
        padding: 14px;
        span {
          &:nth-child(2) {
            width: 78%;
            text-align: right;
            color: #24E5A0;
            font-size: 15px;
            font-weight: 550;
          }
        }
      }
    }
  }
</style>