<template>
  <div class='indexbox'>
    <nav-bar title="产品订单" left-arrow />
    <div class="bodybox">
      <van-tabs v-model="active" color="#24E5A0" title-inactive-color="#fff" title-active-color="#24E5A0" sticky
        offset-top="12.4vw" background="#110F20" @click="onClick">
        <van-tab :title="item.name" v-for="item in toplist" :key="item.id">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
            <div class="item" v-for="val in list" :key="val.id">
                <div class="tit">
                  <p>交易订单号：{{ val.order_sn }}</p>
                </div>
                <div class="i"><span class="l">产品</span><span class="n">{{ val.name }}</span></div>
                <div class="i"><span class="l">周期</span><span class="r">{{ val.cycle }}天</span></div>
                <div class="i"><span class="l">每日产出</span><span class="r">{{ val.every_day }}</span></div>
                <div class="i"><span class="l">已产出</span><span class="n">{{ val.already_num }}</span></div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        toplist: [{ name: '进行中', id: 1 }, { name: '已完成', id: 2 }],
        active: 0,
        list: [],
        page: 1,
        loading: false,
        finished: false,
      }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
      onClick() {
        this.page = 1
        this.list = []
        this.finished = false
        this.onLoad()
      },
      onLoad() {
        let page = this.page++
        this.$get({
          url: "/product_order/lists",
          data: {
            status: Number(this.active) + 1,
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .bodybox {
    width: 100%;
    padding: 0 15px 20px;
    /deep/.van-sticky--fixed {
      padding: 0 15px 20px;
    }
    /deep/.van-tabs {
      width: 100%;
      .van-tabs__wrap {
        border: 1px solid #3C4E60;
        border-radius: 2px;
      }
    }
    .item {
      background: #110F20;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3C4E60;
      margin-top: 15px;
      padding: 15px 15px 3px;
      .i {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .l {
          width: 29%;
        }
        >span {
          color: #fff;
          font-size: 14px;
          &:nth-child(2) {
            width: 70%;
            text-align: right;
          }
        }
        .n {
          color: #24E5A0;
          font-size: 15px;
          font-weight: 550;
        }
        .t {
          font-size: 13px;
          color: #999999;
        }
      }
      .tit {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 0 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #303C4E;
        p {
          width: 80%;
          font-size: 13px;
        }
        span {
          font-size: 15px;
          font-weight: 550;
        }
        .g {
          color: #55DD84;
        }
        .y {
          color: #DDB355;
        }
      }
    }
  }
</style>